<template>
	<Div>
		<img :src="proxy.$axios.imgUrl + topImg" class="topImg" />
		<div class="contactUs">
			<div class="Company-title jucenter">
				Contact us
				<div class="Company-title-border flex-center">
					<div class="red"></div>
					<div class="blue"></div>
				</div>
			</div>
			<div class="contactUs-list flex">
				<div class="contactUs-item " v-for="(item,index) in contactUsList" :key="index">
					<img :src="item.icon" />
					<div class="title">{{item.title}}</div>
					<div class="content">{{bottomInfo[item.content]}}</div>
				</div>
			</div>
			<div class="Company-bottom flex-center">
				<img src="../../assets/image/ic_gongstu.png" />
				{{bottomInfo.web_site_title}}
			</div>
		</div>
	</Div>
</template>

<script setup>
	import {
		ref,
		onMounted,
		getCurrentInstance,
	} from 'vue';
	import {
		useRouter
	} from 'vue-router'
	import EventBus from "../../assets/common/event-bus.js"
	const {
		proxy
	} = getCurrentInstance()

	onMounted(() => {
		EventBus.emit("mgheadr", 4)
		getbottomInfo()
		getTopimg()
	})

	const contactUsList = ref([{
			title: 'Address',
			icon: require('../../assets/image/ic_dizlxwm.png'),
			content: 'address'
		},
		{
			title: 'Website',
			icon: require('../../assets/image/ic_webwz.png'),
			content: 'website'
		},
		{
			title: 'Telephone',
			icon: require('../../assets/image/ic_telphone.png'),
			content: 'telephone'
		},
		{
			title: 'E-mail',
			icon: require('../../assets/image/ic_emaillx.png'),
			content: 'e_mail'
		}
	])

	const bottomInfo = ref({})

	async function getbottomInfo() {
		let res = await proxy.$axios.get('api/index/getTopBottom')
		bottomInfo.value = res.data.bottom
	}


	const topImg = ref('')

	async function getTopimg() {
		let res = await proxy.$axios.get('/api/index/getTopImg', {
			type: 'about'
		})
		topImg.value = res.data.top_img
	}
</script>

<style scoped>
	.topImg {
		width: 100%;
		height: auto;
		margin-top: 0.05rem;
		display: block;
	}

	.contactUs {
		/* margin-top: 0.24rem; */
		background: url('../../assets/image/bg_conusbj.png') no-repeat;
		background-size: 100% 100%;
		padding: 0.21rem 0 0.16rem;

	}

	.contactUs .Company-title-border {
		left: 50%;
		transform: translateX(-50%);
	}

	.contactUs .contactUs-list {
		margin-top: 0.2rem;
		justify-content: space-evenly;
		text-align: center;
	}

	.contactUs .contactUs-list .contactUs-item {
		width: 1.04rem;
	}

	.contactUs .contactUs-list img {
		width: 0.34rem;
		height: 0.34rem;
		display: block;
		margin: 0 auto;
	}

	.contactUs .contactUs-list .title {
		font-weight: 500;
		font-size: 0.08rem;
		color: #000000;
		line-height: 0.08rem;
		margin-top: 0.02rem;
	}

	.contactUs .contactUs-list .content {
		font-weight: 400;
		font-size: 0.06rem;
		color: #666666;
		line-height: 0.09rem;
		margin-top: 0.07rem;
	}

	.contactUs .Company-bottom {
		margin-top: 0.19rem;
		font-weight: 500;
		font-size: 0.11rem;
		color: #008AD2;
		line-height: 0.1rem;
	}

	.contactUs .Company-bottom img {
		width: 0.16rem;
		height: 0.16rem;
		margin-right: 0.04rem;
		display: block;
	}

	.Company-title {
		font-weight: 500;
		font-size: 0.11rem;
		color: #000000;
		padding-bottom: 0.09rem;
		position: relative;
	}

	.Company-title-border {
		height: 0.02rem;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 0.26rem;
	}

	.Company-title-border .red {
		height: 100%;
		width: 0.05rem;
		background-color: #E83328;
	}

	.Company-title-border .blue {
		height: 100%;
		width: 0.19rem;
		background-color: #008AD2;
		margin-left: 0.02rem;
	}
</style>